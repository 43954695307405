import React from 'react';
import './Reputation.css';

import ImageUtils from '@components/imageUtils';
import { HandySvg } from 'handy-svg';
const images = ImageUtils.importAllImages(require.context('@assets/about/reputation', false, /\.(svg)$/));
const pngImages = ImageUtils.importAllImages(require.context('@assets/about/reputation/png', false, /\.(png)$/));

export default function Reputation() {
  return (
    <div className='about-reputation'>
      <div className='label-chapter-box'>РЕПУТАЦИЯ</div>
      <div className='label'>Что говорят о ToUP</div>
      <div className='description'>Любим делиться отзывами наших клиентов – они уже успели заценить наш сервис. Работаем
        над собой, чтобы радовать вас ещё больше!
      </div>
      <div className='about-reviews-container'>
        <div className='review-item'>
          <div className='review-card'>
            <div className='users-container'>
              <img src={pngImages['user-1.png']} alt={'user1'}/>
              <img src={pngImages['user-2.png']} alt={'user2'} style={{left: '-15px', zIndex: 2}}/>
              <img src={pngImages['user-3.png']} alt={'user3'} style={{left: '-30px', zIndex: 3}}/>
              <img src={pngImages['user-4.png']} alt={'user4'} style={{left: '-45px', zIndex: 4}}/>
            </div>
            <div className='users-text'>Наши клиенты оценивают
              нас как самый современный
              и эффективный сервис
              по накрутке в Telegram
            </div>
          </div>
          <HandySvg src={images['slash.svg']} className='slash mobile' />
        </div>
        <HandySvg src={images['slash.svg']} className='slash' />
        <div className='review-item back'>
          <div className='review-card'>
            <img src={images['stars.svg']} alt={'stars'}/>
            <div className='review-text'>4,5 из 5 звезд из 123 отзывов пользователей</div>
          </div>
        </div>
        <HandySvg src={images['slash.svg']} className='slash' />
        <div className='review-item'>
          <div className='review-card'>
            <img src={images['payments.svg']} alt={'payments'}/>
            <div className='payment-text'>Различные варианты оплаты и покупок для наших клиентов</div>
          </div>
          <HandySvg src={images['backslash.svg']} className='slash mobile' />
        </div>
      </div>
    </div>
  )
}
