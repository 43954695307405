import React, { useContext } from 'react';
import { ThemeContext } from '@context/ThemeContext';
import { AuthContext } from '@context/AuthContext';
import './HelloBlock.css';

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/partners/becomePartners/helloBlock', false, /\.(svg)$/));

export default function HelloBlock() {
  const { theme } = useContext(ThemeContext);
  const { isAuthenticated, handleLogin } = useContext(AuthContext);

  const handleCreateTicket = () => {
    // createTicket();
    window.open('https://t.me/toupSupport_bot?start=partner', '_blank');
  };
  
  return (
    <div className='hello-block-background'>
      <div className="hello-block">
        <div className="partner-chapter-box">ХОТИТЕ СТАТЬ ПАРТНЕРОМ?</div>
        <h1 className="title">Мы ценим своих партнеров...</h1>
        <p className='description'>.... и предлагаем им лучшие условия!<br/>
          Всё просто: зарегистрируйтесь, и можно сразу начинать сотрудничать.</p>
        <div className="buttons">
          <button className="primary-button" onClick={isAuthenticated ? handleCreateTicket : handleLogin}>Стать партнером</button>
          <a
            href="https://t.me/toupSupport_bot"
            target="_blank"
            rel="noopener noreferrer"
            className="secondary-button"
          >
            Связаться с нами
          </a>
        </div>
        <div className="features">
          <div className="feature">
            <img src={images['wallet.svg']} alt={'wallet'}/>
            Быстрый вывод
          </div>
          <div className="feature">
            <img src={images['dollar.svg']} alt={'dollar'}/>
            Обязательные бонусы
          </div>
          <div className="feature">
            <img src={images['chart.svg']} alt={'chart'}/>
            Аналитика рефералов
          </div>
        </div>
      </div>
      <img src={images[`takeProfit-${theme}.svg`]} alt={'takeProfit'} className='takeprofit'/>
      <img src={images[`growthReferals-${theme}.svg`]} alt={'growthReferals'} className='growthReferals'/>
      <img src={images[`sumProfit-${theme}.svg`]} alt={'sumProfit'} className='sumProfit'/>
      <img src={images[`profitBalance-${theme}.svg`]} alt={'profitBalance'} className='profitBalance'/>
    </div>
  );
}
