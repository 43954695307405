import React from 'react';
import './Advantages.css';

import ImageUtils from '@components/imageUtils';
import { HandySvg } from 'handy-svg';
const images = ImageUtils.importAllImages(require.context('@assets/partners/becomePartners/advantages', false, /\.(svg)$/));

export default function Advantages() {
  const AdvantageCard = ({ image, label, description }) => {
    return (<div className='partnership-advantage-card'>
      <div className='image-block'>
        <HandySvg src={image} className='logo-20x20' />
      </div>
      <div className='card-label'>{label}</div>
      <div className='card-description'>{description}</div>
      <a href="https://t.me/toupSupport_bot" target="_blank" rel="noopener noreferrer" className='more-info-btn'>
        <span>Подробнее
          <HandySvg src={images['arrow.svg']} className='logo-15x15 currentColor' />
        </span>
      </a>
    </div>);
  }

  return (
    <div className='partnership-advantages'>
      <div className='home-chapter-box'>ПРЕИМУЩЕСТВА</div>
      <div className='label'>Почему партнёрство с нами круче,<br/>
        чем с другими?
      </div>
      <div className='partnership-advantages-container'>
        <AdvantageCard
          image={images['timeFast.svg']}
          label='Мгновенные выплаты'
          description='Вывод средств доступен в любое время и в любой день. Сумма минимального вывода составляет $50.'
        />
        <AdvantageCard
          image={images['benefit.svg']}
          label='Без комиссий и скрытых платежей'
          description={<>Все заработанное - ваше.<br/> Никаких дополнительных сборов.</>}
        />
        <AdvantageCard
          image={images['sackDollar.svg']}
          label='Крутые проценты за рефералов'
          description='Получайте 10% от первого пополнения каждого приглашённого.'
        />
        <AdvantageCard
          image={images['coins.svg']}
          label='Бонусы за рефералов - фиксированные'
          description='Кроме процентов, мы платим фиксированно за каждых 100 рефералов.'
        />
        <AdvantageCard
          image={images['gift.svg']}
          label='Подарки топ-партнёрам'
          description='Лучшие из лучших не остаются без наград! Попадайте в топ и забирайте крутые призы.'
        />
      </div>
    </div>
  )
}
