import React, { useContext } from 'react'
import { ThemeContext } from '@context/ThemeContext';
import { AuthContext } from '@context/AuthContext';
import './About.css'

import { HandySvg } from 'handy-svg';
import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/home', false, /\.(svg)$/));

export default function About() {
  const { theme } = useContext(ThemeContext);
  const { handleRegistration } = useContext(AuthContext);

  const listAdvantages = [
    'Понятный интерфейс',
    'Гибкие настройки под любые цели',
    'Естественная активность — словно органика',
    'Подписка вместо оплаты за каждое действие',
    'Самостоятельный контроль темпа и направления роста',
    'Надежные боты для реалистичной активности',
  ]

  return (
    <div className='about-background'>
      <div className='about'>
        <div className='about-main'>
          <span className='home-chapter-box'>Почему ToUP?</span>
          <div className='label'>ToUp — это современный сервис, в котором вы найдете:</div>
          {/*<div className='text'>Мы предлагаем надежную накрутку подписчиков, просмотров и реакций, чтобы ваша группа стала еще более популярной и востребованной. Присоединяйтесь к нам и увидите, как ваша группа достигает новых высот!</div>*/}
          <div className='about-table-container'>
            {listAdvantages.map((advantage, i) => {
              return (
                <div className='about-table-item' key={i}>
                  <HandySvg src={images[`success.svg`]} />
                  {advantage}
                </div>
              );
            })}
          </div>
          <div className='create-account-button' onClick={handleRegistration}>Создать аккаунт</div>
        </div>
        <div className='about-image'>
          <img src={images[`about-${theme}.svg`]} alt='about'/>
        </div>
      </div>
    </div>
  );
};