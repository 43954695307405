import React, { useContext } from 'react';
import { AuthContext } from '@context/AuthContext';
import './HelloBlock.css';

import ImageUtils from '@components/imageUtils';
import { HandySvg } from 'handy-svg';
const images = ImageUtils.importAllImages(require.context('@assets/about/helloBlock', false, /\.(svg)$/));

export default function HelloBlock() {
  const { handleRegistration } = useContext(AuthContext);

  return (
    <div className='about-hello-block-background'>
      <div className='about-hello-block'>
        <div className='label-chapter-box'>О НАШЕЙ КОМПАНИИ</div>
        <div className='label'>Крупнейший сервис по накрутке активности в Telegram</div>
        <div className='description'>Надежная накрутка подписчиков, просмотров и реакций. <br/>Сделайте свой канал еще
          популярнее и востребованнее с ToUP.
        </div>
        <div className="buttons">
          <button className="primary-button" onClick={handleRegistration}>Начать сейчас</button>
          <a
            href="https://t.me/toupSupport_bot"
            target="_blank"
            rel="noopener noreferrer"
            className="secondary-button"
          >
            Связаться с нами
          </a>
        </div>
        <HandySvg src={images['profile.svg']} />
      </div>
    </div>
  )
}
