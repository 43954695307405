import React, {useRef, useState, useEffect} from 'react';
import ImageUtils from '@components/imageUtils';
import InputField from "@components/shared/InputField";
import {HandySvg} from "handy-svg";
import './Filter.css';

const images = ImageUtils.importAllImages(require.context('@assets/tasks', false, /\.(svg)$/));

const Filter = ({filterData, handleFilterChange}) => {
  const [isFilterWindowOpen, setFilterWindowOpen] = useState(false);
  const filterButtonRef = useRef(null);
  const filterRef = useRef(null);

  useEffect(() => {
    const handleClickFilterOutside = (event) => {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        filterButtonRef.current &&
        !filterButtonRef.current.contains(event.target)
      ) {
        setFilterWindowOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickFilterOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickFilterOutside);
    };
  }, []);

  // Компонент для выбора типа задачи
  const TaskTypeFilter = () => {
    const handleClear = () => {
      handleFilterChange('type', 'subs', false);
      handleFilterChange('type', 'views', false);
      handleFilterChange('type', 'react', false);
    };

    return (
      <div className="filter-section">
        <div className="filter-section--header">
          <span>Тип задачи</span>
          <button className="ghost-button" onClick={handleClear}>Очистить</button>
        </div>
        <div className="filter-section--items">
          <label
            onClick={() => handleFilterChange('type', 'subs', !filterData.type.subs)}
            className={filterData.type.subs ? 'active' : ''}
          >
            <span/>
            Подписки
          </label>
          <label
            onClick={() => handleFilterChange('type', 'views', !filterData.type.views)}
            className={filterData.type.views ? 'active' : ''}
          >
            <span/>
            Просмотры
          </label>
          <label
            onClick={() => handleFilterChange('type', 'react', !filterData.type.react)}
            className={filterData.type.react ? 'active' : ''}
          >
            <span/>
            Реакции
          </label>
        </div>
      </div>
    );
  };

  // Компонент для выбора статуса задачи
  const TaskStatusFilter = () => {
    const handleClear = () => {
      handleFilterChange('status', 'active', false);
      handleFilterChange('status', 'pause', false);
      handleFilterChange('status', 'ended', false);
      handleFilterChange('status', 'cancel', false);
    };

    return (
      <div className="filter-section">
        <div className="filter-section--header">
          <span>Статус задачи</span>
          <button className="ghost-button" onClick={handleClear}>Очистить</button>
        </div>
        <div className="filter-section--items">
          <label
            onClick={() => handleFilterChange('status', 'active', !filterData.status.active)}
            className={filterData.status.active ? 'active' : ''}
          >
            <span/>
            Активна
          </label>
          <label
            onClick={() => handleFilterChange('status', 'pause', !filterData.status.pause)}
            className={filterData.status.pause ? 'active' : ''}
          >
            <span/>
            На паузе
          </label>
          <label
            onClick={() => handleFilterChange('status', 'ended', !filterData.status.ended)}
            className={filterData.status.ended ? 'active' : ''}
          >
            <span/>
            Выполнена
          </label>
          <label
            onClick={() => handleFilterChange('status', 'cancel', !filterData.status.cancel)}
            className={filterData.status.cancel ? 'active' : ''}
          >
            <span/>
            Отмена
          </label>
        </div>
      </div>
    );
  };

  // Компонент для выбора канала задачи
  const TaskChannelFilter = () => {
    const handleClear = () => {
      handleFilterChange('link', 'link', '');
    };

    return (
      <div className="filter-section" style={{paddingBottom: '0px'}}>
        <div className="filter-section--header" style={{marginBottom: '5px'}}>
          <span>Канал задачи</span>
          <button className="ghost-button" onClick={handleClear}>Очистить</button>
        </div>

        <InputField
          label=""
          type="text"
          placeholder="Ссылка канала"
          logo={images['telegram.svg']}
          value={filterData.link.link}
          handleChange={(e) => handleFilterChange('link', 'link', e.target.value)}
        />
      </div>
    );
  };

  // Компонент окна фильтров
  const FilterWindow = () => {
    const filterButton = filterButtonRef.current;

    if (filterButton) {
      const rect = filterButton.getBoundingClientRect();
      return (
        <div
          ref={filterRef}
          className="filter-window"
          style={{
            top: `${rect.bottom + window.scrollY + 10}px`,
            left: `${rect.right + window.scrollX - 346}px`,
          }}
        >
          <h5>Фильтры</h5>
          <TaskTypeFilter/>
          <TaskStatusFilter/>
          <TaskChannelFilter/>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <button
        ref={filterButtonRef}
        onClick={() => setFilterWindowOpen(!isFilterWindowOpen)}
        className="filter-btn desktop-btn"
      >
        <HandySvg src={images['filter.svg']} className="logo-20x20" alt="settings"/>
      </button>
      {isFilterWindowOpen && <FilterWindow/>}
    </>
  );
};

export default Filter;
