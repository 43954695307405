import React from 'react';
import './Service.css';

import ImageUtils from '@components/imageUtils';
import { HandySvg } from 'handy-svg';
const images = ImageUtils.importAllImages(require.context('@assets/about/service', false, /\.(svg)$/));

export default function Service() {
  const AdvantageBlock = ({ image, label }) => (
    <div className='service-advantage-block'>
      <HandySvg src={image} className='logo-20x20' />
      <span className='label'>{label}</span>
    </div>
  );

  const AdvantageCard = ({ image, label, description }) => {
    return (<div className='partnership-advantage-card'>
      <div className='image-block'>
        <HandySvg src={image} className='logo-20x20' />
      </div>
      <div className='card-label'>{label}</div>
      <div className='card-description'>{description}</div>
      <a href="https://t.me/toupSupport_bot" target="_blank" rel="noopener noreferrer" className='more-info-btn'>
        <span>Подробнее</span>
        <HandySvg src={images['arrow.svg']} className='logo-15x15 currentColor' />
      </a>
    </div>);
  }

  return (
    <>
      <div className='about-service-cards'>
        <div className='services-advantages-container'>
          <AdvantageBlock
            image={images['analyse.svg']}
            label='Аналитика вашего канала'
          />
          <AdvantageBlock
            image={images['bolt.svg']}
            label='Быстрый рост аудитории'
          />
          <AdvantageBlock
            image={images['user-headset.svg']}
            label='Личная поддержка 24/7'
          />
          <AdvantageBlock
            image={images['bonus.svg']}
            label='Бонусы и розыгрыши'
          />
        </div>
      </div>
      <div className='about-service-1'>
        <div className='label-chapter-box' style={{marginTop: '65px'}}>Бонусы работы с ToUP</div>
        <div className='label'>Реалистичная активность в Telegram</div>
        <div className='description'>С помощью ToUP вы сможете увеличить видимостью канала, добавить авторитетности,
          привлечь новых подписчиков, ускорить развитие проекта и, конечно же, бустануть активность и увеличить охваты.
        </div>
        <div className='partnership-advantages-container'>
          <AdvantageCard
            image={images['note.svg']}
            label='Полный контроль задач и удобство управления'
            description='Вы сами выбираете параметры накрутки: темпы, временные интервалы и типы активности.  Всё максимально просто и под вашим контролем.'
          />
          <AdvantageCard
            image={images['message-bot.svg']}
            label='Экономия времени и сил'
            description='Значимость этих проблем настолько очевидна, что консультация с широким активом требуют от нас анализа дальнейших направлений развития.'
          />
          <AdvantageCard
            image={images['tachometr.svg']}
            label='Быстрый старт и высокая скорость сервиса'
            description='После настройки задачи результат не заставит себя ждать – активность начинается почти мгновенно.'
          />
          <AdvantageCard
            image={images['bonus.svg']}
            label='Бонусы и программы лояльности'
            description='Регулярные акции, розыгрыши и бонусы делают работу с ToUP ещё более выгодной и приятной.'
          />
          <AdvantageCard
            image={images['benefit.svg']}
            label='Индивидуальный подход к каждому клиенту'
            description='Мы всегда на связи и готовы помочь настроить задачи под ваши конкретные цели.'
          />
          <AdvantageCard
            image={images['earnings.svg']}
            label='Абсолютная безопасность аккаунта'
            description='Наши алгоритмы соответствуют обновлениям соцсетей, поэтому вам не грозит блокировка за использование сервиса.'
          />
        </div>
      </div>
    </>
  )
}
