import React, { useState, useContext } from 'react'
import { ThemeContext } from '@context/ThemeContext';
import { AuthContext } from '@context/AuthContext';
import './HowItWork.css'

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/home', false, /\.(svg)$/));

export default function HowItWork() {
  const { theme } = useContext(ThemeContext);
  const { handleRegistration } = useContext(AuthContext);

  const menus = [
    {
      buttonLabel: "Создайте аккаунт",
      label: 'Для начала работы:',
      text: <>Зарегистрируйтесь через email и Telegram.<br/>
        Настройте задачи: просмотры, реакции или подписки.<br/>
        Запустите накрутку сразу или составьте свой график активности.
      </>,
      name: 'registration'
    }, 
    {
      buttonLabel: "Приобретите подписку",
      label: 'Приобретите один из тарифов или соберите свой',
      text: 'Выберите готовый тариф с оптимальными настройками или создайте индивидуальный план под ваши цели и бюджет!',
      name: 'subscribe'
    }, 
    {
      buttonLabel: "Запустите задачу", 
      label: 'Выставите необходимые параметры и запустите задачу',
      text: 'Настройте все параметры под свои задачи и цели — быстро, просто и с максимальным удобством!',
      name: 'runTask'
    }, 
    {
      buttonLabel: "Наблюдайте за прогрессом",
      label: 'Смотрите, как аудитория вашего канала растет на глазах',
      text: 'Наблюдайте, как ваша аудитория увеличивается с каждым часом — сотни подписчиков, тысячи просмотров и максимум активности уже в первые сутки!',
      name: 'progress'
    }, 
  ]
  const [activeMenu, setActiveMenu] = useState(0)

  return (
    <div className='how-it-work'>
      <div className='home-chapter-box'>КАК ЭТО РАБОТАЕТ?</div>
      <div className='label'>Ознакомьтесь с этапами работы с нами</div>
      <div className='how-it-work-menus'>
        {menus.map((menu, index) => (
          <div key={index}
            className={`how-it-work-menu ${index === activeMenu ? 'active' : ''}`}
            onClick={() => setActiveMenu(index)}
          >
            {menu.buttonLabel}
          </div>
        ))}
      </div>
      <div className='how-it-work-container'>
        <div className='how-it-work-main'>
          <img src={images[`registration.svg`]} alt={'registration'}/>
          <div className='label'>{menus[activeMenu].label}</div>
          <div className='text'>{menus[activeMenu].text}</div>
          <div className='link' onClick={handleRegistration}>
            Создать аккаунт
            <img src={images[`angle.svg`]} alt={'angle'}/>
          </div>
        </div>
        <div className='how-it-work-image'>
          <img src={images[`${menus[activeMenu].name}-${theme}.svg`]} alt={`${menus[activeMenu].name}-${theme}.svg`}
               className='how-it-work-image'/>
        </div>
      </div>
    </div>
  );
};
