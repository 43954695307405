import React from 'react';
import './Reviews.css';

import ImageUtils from '@components/imageUtils';
const images = ImageUtils.importAllImages(require.context('@assets/home', false, /\.(svg)$/));
const avatars = ImageUtils.importAllImages(require.context('@assets/home/avatars', false, /\.(png)$/));

export default function Reviews() {
  // const msg = "“Идейные соображения высшего порядка, а также консультация с широким активом позволяет оценить значение системы обучения кадров”";
  const reviews = [
    {
      author: "Александр Ковалёв",
      post: "Маркетолог диджитал-агентства",
      avatar: "1.png",
      msg: '“Работаем с ToUP уже второй год. Очень нравится, что сервис позволяет полностью контролировать темпы роста активности и выглядит максимально натурально.”'
    },
    {
      author: "Дмитрий Смирнов",
      post: "Руководитель SMM-отдела",
      avatar: "3.png",
      msg: '“ToUP – это находка, чтобы быстро прокачать Telegram-каналы. Очень удобный интерфейс, понятные настройки, и что самое главное – никаких проблем с безопасностью.”'
    },
    {
      author: "Егорова Анна",
      post: "Трафик-менеджер",
      avatar: "2.png",
      msg: '“ToUP стал незаменимым инструментом в нашей работе. Возможность самостоятельно настраивать задачи до мельчайших деталей – это то, что нужно для эффективного продвижения.”'
    },
    {
      author: "Рябова Варвара",
      post: "Владелец Telegram-агентства",
      avatar: "4.png",
      msg: '“Мы перепробовали множество сервисов, но ToUP оказался на голову выше остальных. Боты работают плавно и реалистично, отчётность прозрачная, а результат впечатляет.”'
    },
  ];

  return (
    <div className='reviews'>
      {reviews.map((review, index) => {
        return (
          <div className='review-item' key={index}>
            <div >
              <img src={images[`quotes.svg`]} alt="quotes" />
            </div>
            <span className='review-text'>{review.msg}</span>
            <div className='review-user'>
              <img src={avatars[review.avatar]} className='review-user-avatar' alt="avatar" />
              <div className='review-user-text'>
                <span className='review-username'>{review.author}</span>
                <span className='review-user-post'>{review.post}</span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};